import classnames from 'classnames'
import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { Col, Row, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Card, CardBody, CardText, Collapse, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

import {
  getProjectsCentersAction,
  getCentersDevicesAction,
  closeModalAction,
  newCentersDevicesAction,
  centersDevicesUpdateCountDownAction,
  deleteCentersDeviceAction,
} from '../../store/planlama-ve-takvim/actions'

function getFileName(fullPath) {
  if (fullPath) {
    var startIndex = fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/')
    var filename = fullPath.substring(startIndex)
    if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
      filename = filename.substring(1)
    }
    return filename
  }
}

const APP = props => {
  console.log(props)
  const [current_centers, set_current_centers] = useState(null)

  const dispatch = useDispatch()

  // TODO:
  const { projects_centers, close_modal, centers_devices } = useSelector(state => ({
    projects_centers: state.PlanlamaVeTakvim.projects_centers,
    close_modal: state.PlanlamaVeTakvim.close_modal,
    centers_devices: state.PlanlamaVeTakvim.centers_devices,
  }))

  const get_centers = () => {
    if (props.current_proje && props.current_proje.id) {
      dispatch(getProjectsCentersAction(props.current_proje.id))
    }
  }

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'))
      setUserType(obj.user_type)
    }
  }, [props.success])

  const [user_type, setUserType] = useState('DCT')

  useEffect(() => {
    get_centers()
    if (props.current_proje) {
      set_current_centers(projects_centers[props.current_proje.id])
    }
  }, [props.current_proje])


  useEffect(() => {
    if (props.current_proje) {
      set_current_centers(projects_centers[props.current_proje.id])
    }
  }, [projects_centers, props.current_proje])

  useEffect(() => {
    if (current_centers && current_centers.length > 0) {
      toggleVertical(current_centers[0].id)
    }
  }, [current_centers])

  ///
  const [is_uploading, setIsUploading] = useState(false)

  useEffect(() => {
    if (close_modal === true) {
      set_modal_opened(false)
      set_modal2_opened(false)
      dispatch(closeModalAction(false))
      setIsUploading(false)
    }
  }, [close_modal])

  ///
  const [active_center_devices, set_active_center_devices] = useState([])
  const [active_center_id, set_active_center_id] = useState(null)
  const [new_device_name, set_new_device_name] = useState(null)
  const [new_device_countdown_day, set_new_device_countdown_day] = useState(null)
  const [c_device_id, set_c_device_id] = useState(null)

  useEffect(() => {
    if (centers_devices) {
      set_active_center_devices(centers_devices[active_center_id])
    }
  }, [centers_devices])

  const toggleVertical = tab => {
    if (active_center_id !== tab) {
      set_active_center_id(tab)
    }
  }

  const get_centers_devices = () => {
    if (active_center_id) {
      dispatch(getCentersDevicesAction(active_center_id))
    }
  }

  useEffect(() => {
    get_centers_devices()
  }, [active_center_id])

  const [modal_opened, set_modal_opened] = useState(false)
  const [modal2_data, set_modal2_opened] = useState(false)

  const handle_new_device = () => {
    let $file = document.querySelector('#uploadFile')
    let full_file_name = getFileName($file.value)

    var reader = new FileReader()
    reader.readAsDataURL($file.files[0])
    reader.onload = function () {
      dispatch(newCentersDevicesAction(active_center_id, new_device_name, reader.result, full_file_name, new_device_countdown_day))
    }
    setIsUploading(true)
  }
  const update_new_device = () => {
    dispatch(centersDevicesUpdateCountDownAction(c_device_id, active_center_id, new_device_countdown_day))

  }

  const delete_device = (center_id, device_id) => {
    console.log(center_id, device_id)
    console.log(c_device_id)
    if (confirm('Emin misiniz?')) {
      dispatch(deleteCentersDeviceAction(center_id, device_id))
    }
  }
  const daysChange = (daySet) => {
    set_new_device_countdown_day(daySet)
    set_modal2_opened(true)
    return true;
  }
  const idSetter = (id) => {
    set_c_device_id(id)
    return true;
  }
  const countDownFunc = (datetimeStr, days, id) => {
    const inputDate = new Date(datetimeStr);
    const currentDate = new Date();
    const diffMilliseconds = currentDate - inputDate;
    const diffDays = Math.ceil(diffMilliseconds / (1000 * 60 * 60 * 24));
    if ((diffDays < days) == true) {
      return (
        <div>
          <span class='btn btn-success'>{Math.ceil(days - diffDays)} Gün Var</span>
          <>
            <Button
              color="btn btn-primary"
              onClick={() => {
                idSetter(id)
                daysChange(days)
              }}>
              Güncelle
            </Button>
          </>
        </div>
      );
    }
    else if (diffDays == days) {
      return (
        <div>
          <span class='btn btn-warning'>Bugün Bitiyor!!!</span>
          <>
            <Button
              color="btn btn-primary"
              onClick={() => {

                idSetter(id)
                daysChange(days)
              }}>
              Güncelle
            </Button>
          </>
        </div>
      );
    }
    else {
      return (
        <div>
          <span class='btn btn-danger'>Süre Bitti!!!</span>
          <>
            <Button
              color="btn btn-primary"
              onClick={() => {
                idSetter(id)
                daysChange(days)
              }}>
              Güncelle
            </Button>
          </>
        </div>

      );
    }
  }

  return (
    <React.Fragment>
      <Row>
        {current_centers && current_centers.length ? (
          <></>
        ) : (
          <>
            <h4 className="mt-3">Lütfen öncelikle merkezleri oluşturun</h4>
          </>
        )}
        <Col xs={12}>
          <Row>
            <Col md="3">
              <Nav pills className="flex-column">
                {current_centers ? (
                  current_centers.map(center => (
                    <NavItem>
                      <NavLink
                        style={{ cursor: 'pointer' }}
                        className={classnames({
                          'mb-2': true,
                          active: active_center_id === center.id,
                        })}
                        onClick={() => {
                          toggleVertical(center.id)
                        }}>
                        {center.name}
                      </NavLink>
                    </NavItem>
                  ))
                ) : (
                  <></>
                )}
              </Nav>
            </Col>
            <Col md="9">
              {current_centers ? (
                current_centers.map((center, index) => (
                  <TabContent activeTab={active_center_id} className="text-muted mt-4 mt-md-0">
                    <TabPane tabId={center.id}>
                      <h4>{center.name}</h4>
                      {active_center_devices ? (
                        active_center_devices.map(device => (
                          <Row className="mt-3">
                            <Col xs={3}>
                              <Input type="text" value={device.name} disabled />
                            </Col>
                            <Col xs={3} className="d-flex align-items-center">
                              <a href={device.file}>{device.file_name}</a>
                            </Col>
                            <Col xs={3} className="d-flex align-items-center">
                              {countDownFunc(device.create_datetime, device.countdown, device.id)}
                            </Col>
                            <Col xs={3} className="d-flex justify-content-center">
                              <Button onClick={delete_device.bind(this, center.id, device.id)} color="primary">
                                Sil1
                              </Button>
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <></>
                      )}
                      <Row className="mt-3">
                        <Col>
                          {user_type == 'ADM' || user_type == 'PM' || user_type == 'CRA' ? (
                            <Button
                              color="success"
                              onClick={() => {
                                set_modal_opened(!modal_opened)
                              }}>
                              Ekle
                            </Button>
                          ) : (
                            ''
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                ))
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {/* NEW */}

      <Modal
        isOpen={modal_opened}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          set_modal_opened(!modal_opened)
        }}>
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              set_modal_opened(!modal_opened)
            }}>
            Yeni Merkez device
          </ModalHeader>

          <ModalBody>
            {!is_uploading ? (
              <>
                <div className="mb-3">
                  <Label>Name</Label>
                  <Input
                    type="text"
                    className="form-control"
                    value={new_device_name}
                    onChange={e => {
                      set_new_device_name(e.target.value)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <input type="file" className="form-control-file" id="uploadFile" />
                </div>
                <Label>Gün Sayısı</Label>
                <Input
                  type="number"
                  className="form-control"
                  value={new_device_countdown_day}
                  onChange={e => {
                    set_new_device_countdown_day(e.target.value)
                  }}
                />
              </>
            ) : (
              <p>Uploading, please wait...</p>
            )}
          </ModalBody>
          <ModalFooter>
            {!is_uploading ? (
              <>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    set_modal_opened(!modal_opened)
                  }}>
                  Kapat
                </Button>
                <Button type="button" color="primary" onClick={handle_new_device}>
                  Ekle
                </Button>
              </>
            ) : (
              <></>
            )}
          </ModalFooter>
        </div>
      </Modal>
      {/* Update  */}
      <Modal
        isOpen={modal2_data}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          set_modal2_opened(!modal2_data)
        }}>
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              set_modal2_opened(!modal2_data)
            }}>
            Bakım Zamanı Güncelle
          </ModalHeader>

          <ModalBody>
            {!is_uploading ? (
              <>
                <Label>Gün Sayısı</Label>
                <Input
                  type="number"
                  className="form-control"
                  value={new_device_countdown_day}
                  onChange={e => {
                    set_new_device_countdown_day(e.target.value)
                  }}
                />
              </>
            ) : (
              <p>Uploading, please wait...</p>
            )}
          </ModalBody>
          <ModalFooter>
            {!is_uploading ? (
              <>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    set_modal2_opened(!modal2_data)
                  }}>
                  Kapat
                </Button>
                <Button type="button" color="primary" onClick={update_new_device}>
                  Güncelle
                </Button>
              </>
            ) : (
              <></>
            )}
          </ModalFooter>
        </div>
      </Modal>
      {/* 2.Modal */}
    </React.Fragment>
  )
}

export default APP
